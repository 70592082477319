.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: $pc;
    padding: $lh / 4;
    padding-top: $lh / 8;
    z-index: 90;
    user-select: none;
    color: $pc;
   // font-size: $big;
}


.header-mute {
    display: inline-block;
    position: absolute;
    top: $lh / 4;
    right: $lh / 4;
    z-index: 900;
    font-size: $medium;
    color: $pc;
    user-select: none;
    cursor: pointer; 
    background: #DA5B24;
    padding: $lh / 4;
    border-radius: $radius / 2;
    color: white;
}

.footer-right {
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    font-size: $big;
    padding: $lh / 4;
    padding-top: 0;
    background: white;
    color: black;
    margin: $lh / 4;
    border-radius: $radius / 8;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;
}

.footer-left {
    position: fixed;
    bottom: 0.5em;
    left: 0;
    color: white;
    margin: $lh / 4;
    padding: $lh / 4;
    user-select: none;
    font-size: 1em;
    opacity: 0.5
}

.header-container {
    position: relative;
    z-index: 1000;
}