.support-button {
    z-index: 900;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #83911F;
    margin: $lh / 2;
    padding: $lh / 2;
    padding-bottom: $lh / 4;
    color: white;
    text-decoration: none;
    border-radius: $lh / 8;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

    img {
        margin-bottom: $lh / 4;
        width: 4em;
        display: block;
    }
}

.menu-button {
    z-index: 900;
    position: fixed;
    top: 0;
    left: 0;
    background: #83911F;
    margin: $lh / 2;
    padding: $lh / 2;
    padding-bottom: $lh / 4;
    color: white;
    text-decoration: none;
    border-radius: $lh / 8;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

    img {
        margin-bottom: $lh / 4;
        width: 4em;
        display: block;
    }
}

.menu-button-close {
    z-index: 950;
    position: fixed;
    top: 0;
    left: 0;
    background: #83911F;
    margin: $lh / 2;
    padding: $lh / 2;
    padding-bottom: $lh / 4;
    color: white;
    text-decoration: none;
    border-radius: $lh / 8;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

    img {
        margin-bottom: $lh / 4;
        width: 4em;
        display: block;
    }
}

.about {
    padding: $lh;
    padding-left: $lh * 5;
    padding-top: $lh * 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $lh;
    overflow: scroll;
    height: 100%;
    background: #6F997E;

    p {
        margin-top: 0;
    }
}

.about-col {
    margin-bottom: 15em;

    h1 {
        font-size: inherit;
        margin-bottom: $lh;
    }

    div {
        margin-bottom: $lh;
    }

    a {
        color: inherit;
    }
}
.logn-text {
    p {
        margin-bottom: $lh / 2;
    }
}
// .about-col {
//     p {
//         margin-bottom: $lh / 2;
//     }

// }

.about-link {
    padding: $lh / 2;
    padding-left: $lh / 2;
    padding-right: $lh / 2;
    background: $sc;
    display: inline-block;
    border-radius: $radius / 2;
    margin-top: $lh / 2;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-decoration: none;
    opacity: 1;
    transition: 0.5s opacity;
}

.about-link:hover {
    opacity: 0.5;
}