.chat {
    // width: 15em;
    // // max-height: 33vh;
    position: fixed;
    bottom: $lh/2;
    right: $lh/2;
    // border-top: 2px solid #8020fd;
    // height: auto;
    // margin: $lh / 4;
    padding: $lh / 4;
    // z-index: 11;
    border-radius: $radius / 8;
    // background: $g1;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

}
.chat-message {
    //background: yellow;
    margin-bottom: $lh / 4;
    border-radius: $lh / 8;
    color: black;
    vertical-align: top;
    // display: inline-block;
    // display: flex;
    scroll-snap-align: end;
}

.chat-name {
    font-size: 0.7em;
    font-weight: bold;
}

.chat-messages {    
    overflow: scroll;
    height: calc(100% - 6.5em);
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    margin-top: $lh / 2;
}

.chat-image {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    border-radius: 100em;
    padding-right: $lh / 4;
    // flex: 0 0 25%;
    width: 17%;

    img {
        width: 100%;
        height: auto;
        border-radius: 100em;
    }
}

.chat-content {
    display: inline-block;
    background: #83911F;
    backdrop-filter: blur(10px);
    opacity: 0.5;
    color: white;
    padding: $lh / 4;
    padding-top: $lh / 8;
    border-radius: $radius / 2;
    max-width: 75%;
    vertical-align: top;
    word-break:  break-word;
    line-height: 1;
    font-size: 1;
}

.chat-input {
    margin-top: $lh / 2;
    position: absolute;
    bottom: $lh * 2.5;
}

.chat-input-field {
    display: inline-block;
    width: calc(100% - 2.6em);
    
}
.chat-input-submit {
    width: 2em;
    margin-left: 0.5em;
    background: #DA5B24;
    border-radius: $radius * 100;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
}

.chat-status {
    margin-top: $lh / 2;
    // background: $g1;
    // border-radius: $radius / 4;
    position: absolute;
    bottom: 0;
}

.chat-avatar {
    width: $lh * 2;
    height: $lh * 2;
    display: inline-block;
    border-radius: 1000em;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    // border: 2px solid white;
    
    img {
        width: 100%;
        height: 100%;
    }
}

.chat-version {
    font-size: 0.5em;
    margin-bottom: 0.5em;
}

.chat-title {
    padding: $lh / 4;
    margin-left: $lh / 4;
    z-index: 500;
    color: $pc;
    display: inline-block;
    height: auto;
    min-width: 10em;
    vertical-align: top;
    background: $g1;
    border-radius: $radius / 4;
    overflow: hidden;
    max-width: 11em;
}

.chat-link {
    border: none;
    font-size: 0.7em;
    text-decoration: none;
    color: white;
    //padding: $lh / 4;
    display: inline-block;
    margin-right: $lh / 4;
    margin-top: $lh / 4;
    background: none;
    opacity: 0.5;
}

  
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  top: 0;
  width: $lh * 2;
  height: $lh / 4;
  background: $pc;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  top: 0;
  left: 0;
  cursor: sw-resize;
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  background: #DA5B24;
  border-radius: $radius / 4;
  transform: translate(0,-$lh / 3);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

